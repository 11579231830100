import { jsx as _jsx } from "react/jsx-runtime";
import { ButtonNavigateRoot } from "@/src/common/components/button/ButtonNavigate";
import { ButtonStandardRoot } from "@/src/common/components/button/ButtonStandard";
import styled, { css } from "styled-components";
const Root = styled.div `
	&& {
		color: ${(p) => { var _a; return (_a = p.$color) !== null && _a !== void 0 ? _a : "inherit"; }};
		display: block;
		//font-size: 18px;
		font-size: 1.4em;
		//font-variation-settings: "FILL" 0, "wght" 100, "GRAD" 0, "opsz" 48; // limit
		//font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 0, "opsz" 48;
		font-variation-settings: ${(p) => {
    if (p.$isFill) {
        return css `"FILL" 1, "wght" 500, "GRAD" 0, "opsz" 24;`;
    }
    return css `"FILL" 0, "wght" 500, "GRAD" 0, "opsz" 24;`;
}}
		//font-variation-settings: "FILL" 1, "wght" 500, "GRAD" 0, "opsz" 24;
		//font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48; // limit
		line-height: 1;
	}
`;
const RootIcon = styled.div `
	${ButtonNavigateRoot} &,
	${ButtonStandardRoot} & {
		//font-size: 0.8em;
	}
`;
export const Symbol = (p) => {
    if (!p.icon) {
        return null;
    }
    return (_jsx(Root, Object.assign({ className: "material-symbols-outlined", "$color": p.color, "$isFill": p.isFill }, { children: _jsx(RootIcon, { children: p.icon }, void 0) }), void 0));
};
