import { jsx as _jsx } from "react/jsx-runtime";
import { Drawer as MUIDrawer } from "@mui/material";
import styled from "styled-components";
export var DrawerAnchorEnum;
(function (DrawerAnchorEnum) {
    DrawerAnchorEnum["Left"] = "left";
    DrawerAnchorEnum["Bottom"] = "bottom";
})(DrawerAnchorEnum || (DrawerAnchorEnum = {}));
const StyledLeftDrawer = styled(MUIDrawer) `
	height: 100%;
	display: flex;
	flex-direction: column;
	width: 320px;
	overflow: auto;
`;
const StyledBottomDrawer = styled(MUIDrawer) `
	&& .MuiDrawer-paper {
		width: 385px;
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
		border-radius: 16px 16px 0 0;
		overflow: hidden;
	}
`;
export const Drawer = ({ anchor, children, open, onClose, PaperProps, }) => {
    if (anchor === DrawerAnchorEnum.Bottom) {
        return (_jsx(StyledBottomDrawer, Object.assign({ anchor: "bottom", open: open, onClose: onClose, PaperProps: PaperProps }, { children: children }), void 0));
    }
    return (_jsx(StyledLeftDrawer, Object.assign({ anchor: "left", open: open, onClose: onClose, PaperProps: PaperProps }, { children: children }), void 0));
};
export default Drawer;
