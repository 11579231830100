import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getUserRelationshipSettings } from "@/src/features/UserRelationship/type";
import { useAuth } from "@/src/hooks/useAuth";
import { ServiceListItem } from "@/src/modules/service/layout/service-list/ServiceListItem";
import { ServiceListSection } from "@/src/modules/service/layout/service-list/ServiceListSection";
import { ServiceListSectionMinorAilment } from "@/src/modules/service/layout/service-list/ServiceListSectionMniorAilment";
import { useVirtualCare } from "@/src/modules/service/layout/virtual-care/useVirtualCare";
import { getSettingDetailsByType } from "@/src/modules/setting/type/SettingDetails";
import { SettingType } from "@/src/modules/setting/type/SettingType";
import { useUserDexcomQuery } from "@/src/modules/user/redux/ApiCoreUser";
import { useGetUserViewSingleCustomerRelationshipsQuery, useUserRxReviewQuery } from "@/src/redux/apiServices/suiteApi";
import { routes } from "@/src/routes";
import { useHistory } from "react-router";
import styled from "styled-components";
const Root = styled.div `
	& {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}
`;
export const ServiceList = (p) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const auth = useAuth();
    const user = auth.user;
    const history = useHistory();
    const virtualCare = useVirtualCare({});
    const apiRelationships = useGetUserViewSingleCustomerRelationshipsQuery({
        userForGuid: user.guid,
    });
    const relationships = ((_b = (_a = apiRelationships.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.relationships) || [];
    const apiDexcom = useUserDexcomQuery({ userForGuid: user.guid, isAddDependents: true });
    const dexcomIsAvailable = Boolean((_d = (_c = apiDexcom.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.isAvailable);
    const apiRxReview = useUserRxReviewQuery({ userForGuid: user.guid, isAddDependents: true });
    const rxReviewIsAvailable = Boolean((_f = (_e = apiRxReview.data) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.isAvailable);
    const settings = [...user.settings, ...getUserRelationshipSettings(relationships)];
    // TODO: reidenzon - Move to a central place!
    const settingDetailsGroupMedVisit = (((_g = settings
        .filter((s) => s.type == SettingType.GroupMedVisit)
        .find((s) => (s.details || {}).isEnabled)) === null || _g === void 0 ? void 0 : _g.details) || {});
    const settingDetailsGroupVirtualCareDoctor = getSettingDetailsByType(SettingType.GroupVirtualCareDoctor, user.settings);
    const handleDexcom = () => {
        history.push(routes.Dexcom.toPath({}));
    };
    const handleMedicationReview = () => {
        history.push(routes.MedicationReview.toPath({}));
    };
    const handleMedVisit = () => {
        history.push(routes.MedVisit.toPath({}));
    };
    const handleVirtualCare = () => {
        virtualCare({});
    };
    return (_jsxs(Root, { children: [dexcomIsAvailable && (_jsx(ServiceListSection, Object.assign({ title: "Get a Dexcom CGM delivered, fast", description: "Looking for a more convenient and enhanced way to manage type 1 or 2 diabetes? Look no further! We've teamed up with Dexcom to make transitioning to CGM easier." }, { children: _jsx(ServiceListItem, { isEnabled: dexcomIsAvailable, title: "Get Started", onClick: handleDexcom }, void 0) }), void 0)), _jsx(ServiceListSection, Object.assign({ title: "Book a Medication Coaching Session", description: "Are you taking 3 or more medications? Talk to a pharmacist 1-on-1 to make sure you're getting the most out of your medications." }, { children: _jsx(ServiceListItem, { isEnabled: rxReviewIsAvailable, title: "Book Now", onClick: handleMedicationReview }, void 0) }), void 0), _jsx(ServiceListSection, Object.assign({ title: "Get a Prescription", description: "Answer a few simple questions, a healthcare provider will review your answers and fill your prescription." }, { children: _jsx(ServiceListItem, { isEnabled: settingDetailsGroupVirtualCareDoctor.isEnabled, title: "Book Now", onClick: handleVirtualCare }, void 0) }), void 0), _jsx(ServiceListSection
            //
            , Object.assign({ 
                //
                title: "Home Care Services", description: "Our Doctors treat patients with acute, episodic conditions (e.g. gastro, UTI, migraine, respiratory infections) that are unable to leave their home on weeknights, weekends and public holidays." }, { children: _jsx(ServiceListItem
                //
                , { 
                    //
                    isEnabled: settingDetailsGroupMedVisit.isEnabled, title: "Book an appointment", onClick: handleMedVisit }, void 0) }), void 0), _jsx(ServiceListSectionMinorAilment, { settings: settings }, void 0)] }, void 0));
};
