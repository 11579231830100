import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonStandard } from "@/src/common/components/button/ButtonStandard";
import { Field } from "@/src/common/components/field/Field";
import { FieldList } from "@/src/common/components/field/FieldList";
import { UserPasswordYup } from "@/src/common/utility/password/UserPassword";
import { FormId } from "@/src/enums/forms";
import { HyperlinkResolution } from "@/src/enums/hyperlink";
import { Form } from "@/src/features/common";
import { PasswordStrengthIndicator } from "@/src/features/common/PasswordStrengthIndicator";
import { t } from "@/src/features/Localization";
import { useCustomSnackbar } from "@/src/features/utils/CustomSnackbar";
import { FormikTextField } from "@/src/features/utils/Formik/FormikTextField";
import { FormikTextFieldTypes } from "@/src/features/utils/Formik/formikTypes";
import { useHyperlinkResolveSingleMutation } from "@/src/redux/apiServices/suiteApi";
import { setCredentials } from "@/src/redux/slices/authSlice";
import { routes } from "@/src/routes";
import { useQuery } from "@/src/utils/ReactRouterHelpers";
import { FormikProvider, useFormik } from "formik";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
const Root = styled.div `
	width: 500px;
	max-width: 100%;
	text-align: center;
`;
export const NewPassword = () => {
    /* ---------------------------------- Hooks --------------------------------- */
    const { snackbar } = useCustomSnackbar();
    const history = useHistory();
    const dispatch = useDispatch();
    const [hyperlinkResolve, { isLoading, isSuccess, data: changePasswordResponseData }] = useHyperlinkResolveSingleMutation();
    const { query } = useQuery();
    /* -------------------------------- Variables ------------------------------- */
    const hyperlinkToken = (query === null || query === void 0 ? void 0 : query.get("token")) || "";
    /* ---------------------------- Helper Functions ---------------------------- */
    /**
     * Returns true if there are errors within the form
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function hasFormikFormErrors(formik) {
        return Object.keys(formik === null || formik === void 0 ? void 0 : formik.errors).length !== 0;
    }
    /* ----------------------------- Action Handlers ----------------------------- */
    function handleSubmit({ userUpdatedPassword }) {
        hyperlinkResolve({
            password: userUpdatedPassword,
            resolution: HyperlinkResolution.Submit,
            token: hyperlinkToken,
        });
    }
    /* ------------------------------- useEffects ------------------------------- */
    useEffect(() => {
        var _a, _b;
        if (isSuccess) {
            snackbar.success("Your password has been updated.");
            const authToken = (_a = changePasswordResponseData === null || changePasswordResponseData === void 0 ? void 0 : changePasswordResponseData.session) === null || _a === void 0 ? void 0 : _a.token;
            const authTokenStream = (_b = changePasswordResponseData === null || changePasswordResponseData === void 0 ? void 0 : changePasswordResponseData.session) === null || _b === void 0 ? void 0 : _b.tokenStream;
            dispatch(setCredentials({
                token: authToken,
                tokenStream: authTokenStream,
            }));
            history.push(routes.Home.toPath());
        }
    }, [isSuccess, changePasswordResponseData]);
    /* ------------------------------ Formik Setup ----------------------------- */
    const ResetPasswordValidationSchema = Yup.object().shape({
        userUpdatedPassword: UserPasswordYup(),
    });
    const formikInitialValues = {
        userUpdatedPassword: "",
    };
    const formik = useFormik({
        initialValues: formikInitialValues,
        enableReinitialize: true,
        validateOnBlur: true,
        validationSchema: ResetPasswordValidationSchema,
        onSubmit: handleSubmit,
    });
    return (_jsx(FormikProvider, Object.assign({ value: formik }, { children: _jsx(Root, { children: _jsx(Form, Object.assign({ onSubmit: formik.handleSubmit, id: FormId.ResetPassword }, { children: _jsxs(FieldList, { children: [_jsxs(Field, { children: [_jsx(FormikTextField
                                //
                                , { 
                                    //
                                    isSubmitEnter: true, fullWidth: true, formikId: "userUpdatedPassword", type: FormikTextFieldTypes.Password, label: t("changePassword_inputLabel_newPassword") }, void 0), _jsx(PasswordStrengthIndicator, { currentPassword: formik.values.userUpdatedPassword }, void 0)] }, void 0), _jsx(ButtonStandard
                        //
                        , { 
                            //
                            content: "Confirm", isLoading: isLoading, isDisabled: hasFormikFormErrors(formik), isSubmit: true, isWide: true }, void 0)] }, void 0) }), void 0) }, void 0) }), void 0));
};
