import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { css } from "styled-components";
export var ButtonStyle;
(function (ButtonStyle) {
    ButtonStyle["Blank"] = "blank";
    ButtonStyle["SelectDropdown"] = "select-dropdown";
    ButtonStyle["SelectItem"] = "select-item";
    ButtonStyle["SolidGhost"] = "solid-ghost";
    ButtonStyle["SolidPrimary"] = "solid-primary";
    ButtonStyle["TextAnchor"] = "text-anchor";
    ButtonStyle["TextDanger"] = "text-danger";
    ButtonStyle["TextSubtle"] = "text-subtle";
})(ButtonStyle || (ButtonStyle = {}));
const infoMap = new Map();
const infoList = [
    {
        Style: ButtonStyle.Blank,
        Css: css `
			&&,
			&&:hover {
				background: transparent;
				color: inherit;
				text-decoration: none;
			}
		`,
    },
    {
        Style: ButtonStyle.SelectDropdown,
        Css: css `
			&& {
			}

			&&:hover {
				background: ${(p) => p.theme.inputBackground};
			}
		`,
    },
    {
        Style: ButtonStyle.SelectItem,
        Css: css `
			&& {
			}

			&&:hover {
				background: ${(p) => p.theme.inputBackground};
			}
		`,
    },
    {
        Style: ButtonStyle.SolidGhost,
        Css: css `
			&& {
				${TypographyV3.Button};
				border: 2px solid ${(p) => p.theme.buttonPrimary};
				border-radius: 100px;
				color: ${(p) => p.theme.buttonPrimary};
				padding: 12px 18px;
				text-decoration: none;
			}

			&&:hover {
				border-color: ${(p) => p.theme.buttonPrimaryHover};
				color: ${(p) => p.theme.buttonPrimaryHover};
			}
		`,
    },
    {
        Style: ButtonStyle.SolidPrimary,
        Css: css `
			&& {
				${TypographyV3.Button};
				background: ${(p) => p.theme.buttonPrimary};
				border-radius: 100px;
				color: ${(p) => p.theme.invert};
				line-height: 20px;
				padding: 12px 18px;
				text-decoration: none;
			}

			&&:hover {
				background: ${(p) => p.theme.buttonPrimaryHover};
			}
		`,
    },
    {
        Style: ButtonStyle.TextAnchor,
        Css: css `
			&& {
				align-items: start;
				color: ${(p) => p.theme.buttonPrimary};
				display: inline;
				font-size: inherit;
				font-weight: 500;
				text-decoration: underline;
			}

			&&:hover {
				color: ${(p) => p.theme.buttonPrimaryHover};
			}
		`,
    },
    {
        Style: ButtonStyle.TextDanger,
        Css: css `
			&& {
				color: ${(p) => p.theme.statusError};
				font-family: var(--font-family-secondary);
				font-size: inherit;
				font-weight: 500;
				text-decoration: none;
			}

			&&:hover span {
				text-decoration: underline;
			}
		`,
    },
    {
        Style: ButtonStyle.TextSubtle,
        Css: css `
			&& {
				color: ${(p) => p.theme.textHint};
				font-family: var(--font-family-secondary);
				font-size: inherit;
				font-weight: 500;
				text-decoration: none;
			}

			&&:hover span {
				text-decoration: underline;
			}
		`,
    },
];
infoList.forEach((info) => infoMap.set(info.Style, info));
export const getButtonStyleInfo = (s) => {
    return infoMap.get(s);
};
