import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components";
import { ListCTAItem } from "@/src/features/common/ListCTA/ListCTAItem";
const ListCTAContainer = styled.div `
	display: flex;
	flex-direction: column;
`;
export const ListCTA = (p) => {
    var _a;
    return (_jsx(ListCTAContainer, { children: (_a = p.items) === null || _a === void 0 ? void 0 : _a.map((item) => (_jsx(ListCTAItem, Object.assign({}, item), item.idKey))) }, void 0));
};
