import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { Config } from "@/src/common/utility/config/Config";
import { getDeepLinkFromURLSearchParams, redirectAfterLogin } from "@/src/features/DeepLink/DeepLinkHelpers";
import { SSOCustomerRequestType, SSOCustomerResultType, SSOMethodType } from "@/src/features/Login/types";
import { getSSOTokenRegister, setCurrentUserCredentials, setSSOCredentials, } from "@/src/features/RegisterAccount/RegisterAccountSSO/registerAccountSSOSlice";
import { SendSnackbar } from "@/src/features/utils/CustomSnackbar";
import { useCustomHistory } from "@/src/hooks/useCustomHistory";
import AppleLogo from "@/src/public/app/images/png/appleid_button.png";
import { setCredentials } from "@/src/redux/slices/authSlice";
import { routes } from "@/src/routes";
import { useQuery } from "@/src/utils/ReactRouterHelpers";
import { SegmentTrackLoginMethod, useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
/* --------------------------------- Styles --------------------------------- */
const ButtonContainer = styled.div `
	& {
		align-items: center;
		border: 1px solid ${(p) => p.theme.divider};
		border-radius: 4px;
		cursor: pointer;
		display: flex;
		height: 38px;
		justify-content: center;
		overflow: hidden;
		padding-right: 12px;
	}

	& img {
		width: 38px;
	}
`;
const Text = styled.div `
	& {
		${TypographyV3.Button};
	}
`;
export const SSOButtonApple = (p) => {
    const [hasSignedIn, setHasSignedIn] = useState(false);
    const [isRequiresActivation, setIsRequiresActivation] = useState(false);
    const { query } = useQuery();
    const dispatch = useDispatch();
    const history = useHistory();
    const ssoTokenRegister = useSelector(getSSOTokenRegister);
    const { segmentTrackEvent } = useSegmentAnalytics();
    const { historyReplace } = useCustomHistory();
    const institutionCode = (query === null || query === void 0 ? void 0 : query.get("groupCode")) || "";
    const pharmacyGuid = (query === null || query === void 0 ? void 0 : query.get("ph")) || undefined;
    const hasSSOTokenRegister = Boolean(ssoTokenRegister);
    // If the user doesn't already have an account
    function registerUser({ tokenRegister }) {
        dispatch(setSSOCredentials({ tokenRegister, ssoMethod: SSOMethodType.Apple }));
        setHasSignedIn(true);
    }
    // // If the user has an account that hasn't been actiated yet.
    function activateUser({ tokenRegister }) {
        dispatch(setSSOCredentials({ tokenRegister, ssoMethod: SSOMethodType.Apple }));
        setIsRequiresActivation(true);
    }
    function loginUser({ token, tokenStream, user, }) {
        // Ensure the user should have the action permission to login in.
        // and if so set the token credentials.
        if (token) {
            if (user && (user === null || user === void 0 ? void 0 : user.guid) && (user === null || user === void 0 ? void 0 : user.email)) {
                // Track successful registration
                segmentTrackEvent("login", {
                    guid: user === null || user === void 0 ? void 0 : user.guid,
                    email: user === null || user === void 0 ? void 0 : user.email,
                    method: SegmentTrackLoginMethod.Apple,
                });
            }
            dispatch(setCredentials({
                token,
                tokenStream,
            }));
            redirectAfterLogin(history, query);
        }
    }
    function backendSSOLoginOrRegister({ authorizationToken, appleUserData }) {
        var _a, _b;
        // SUITE_API_CORE_URL_BASE_SSO
        const userLoginSSOCustomerEndpoint = "/api/user-login-sso-customer";
        const nameFirst = (_a = appleUserData === null || appleUserData === void 0 ? void 0 : appleUserData.name) === null || _a === void 0 ? void 0 : _a.firstName;
        const nameLast = (_b = appleUserData === null || appleUserData === void 0 ? void 0 : appleUserData.name) === null || _b === void 0 ? void 0 : _b.lastName;
        fetch(userLoginSSOCustomerEndpoint, {
            method: "POST",
            body: JSON.stringify({
                type: SSOCustomerRequestType.SSOApple,
                authorization: authorizationToken,
            }),
        })
            .then((response) => response.json())
            .then((jsonResponse) => {
            var _a, _b;
            const { token, tokenStream } = (jsonResponse === null || jsonResponse === void 0 ? void 0 : jsonResponse.session) || {};
            const { tokenRegister, result } = (jsonResponse === null || jsonResponse === void 0 ? void 0 : jsonResponse.data) || {};
            const { code, messages } = jsonResponse || {};
            let user;
            if (code !== 200) {
                SendSnackbar.handleApiMessages(messages);
                return;
            }
            if ((_a = jsonResponse === null || jsonResponse === void 0 ? void 0 : jsonResponse.data) === null || _a === void 0 ? void 0 : _a.user) {
                user = (_b = jsonResponse === null || jsonResponse === void 0 ? void 0 : jsonResponse.data) === null || _b === void 0 ? void 0 : _b.user;
                dispatch(setCurrentUserCredentials({ user }));
            }
            else if (nameFirst && nameLast) {
                user = {
                    details: {
                        nameFirst,
                        nameLast,
                    },
                };
                dispatch(setCurrentUserCredentials({ user }));
            }
            switch (result) {
                case SSOCustomerResultType.Login:
                    if (token && tokenStream) {
                        loginUser({
                            token,
                            tokenStream,
                            user: user,
                        });
                    }
                    break;
                case SSOCustomerResultType.Register:
                    if (tokenRegister) {
                        registerUser({ tokenRegister });
                    }
                    break;
                case SSOCustomerResultType.Activate:
                    if (tokenRegister) {
                        activateUser({ tokenRegister });
                    }
                    break;
                default:
                    break;
            }
        });
    }
    function signIn() {
        const clientId = "ca.mednow.customer.service";
        const redirectURI = Config.UrlBase;
        window.AppleID.auth.init({
            clientId,
            scope: "name email",
            redirectURI,
            // state : '[STATE]',
            // nonce : '[NONCE]',
            usePopup: true,
        });
        window.AppleID.auth
            .signIn()
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .then((data) => {
            backendSSOLoginOrRegister({
                authorizationToken: data.authorization.code,
                appleUserData: data.user,
            });
        })
            .catch(() => {
            // .catch((err: any) => {
            // const { error } = err || {};
            // From: https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/incorporating_sign_in_with_apple_into_other_platforms
            // Apple states:
            // 		"The only error code that might be returned is user_cancelled_authorize.
            // 		This error code is returned if the user clicks Cancel during the web flow."
            //
            // Therefore, no errors will occur that would require us displaying an error message.
            //
            // Although, the other error other than `{ error: "user_cancelled_authorize" }` is
            // if a popup is used is `{ error: "popup_closed_by_user" }`
            // ... and in this case we also won't be displaying a snackbar error message.
            // However, just in case in the future this changes, this commented out code
            // can be used.
            //
        });
    }
    /* -------------------------------- useEffect ------------------------------- */
    // Use Effect to redirect user to register page
    useEffect(() => {
        // If the credentials have been set, redirect to sso page.
        if (hasSSOTokenRegister && hasSignedIn) {
            history.push(routes.RegisterAccountSSO.toPath({
                deeplink: getDeepLinkFromURLSearchParams(query),
                institutionCode: institutionCode,
                pharmacyGuid: pharmacyGuid,
            }));
        }
        /**
         * https://appleid.apple.com/auth/authorize?client_id=ca.mednow.customer.service&redirect_uri=http%3A%2F%2Flocalhost&response_type=code%20id_token&scope=name%20email&response_mode=web_message&frame_id=f3841b77-f6bc-4e62-b6e3-2a2b7ab33380&m=11&v=1.5.4
         * https://appleid.apple.com/auth/authorize?client_id=ca.mednow.customer.service&redirect_uri=http%3A%2F%2Flocalhost&response_type=code%20id_token&scope=name%20email&response_mode=web_message&frame_id=05ef6125-2ed8-41d1-af70-87cc59fd690d&m=11&v=1.5.4
         */
    }, [hasSSOTokenRegister, hasSignedIn]);
    // Use Effect to redirect user to actication page
    useEffect(() => {
        // If the credentials have been set, redirect to sso page.
        if (hasSSOTokenRegister && isRequiresActivation) {
            historyReplace(routes.LoginUserActivateSSO.toPath(), {
                tokenRegister: ssoTokenRegister,
                ssoMethod: SSOMethodType.Apple,
            });
        }
    }, [hasSSOTokenRegister, isRequiresActivation, ssoTokenRegister]);
    /* ---------------------------- Render Component ---------------------------- */
    return (_jsxs(ButtonContainer, Object.assign({ onClick: signIn }, { children: [_jsx("img", { src: AppleLogo, alt: "" }, void 0), _jsxs(Text, { children: [p.isLogin && "Continue with Apple", p.isRegister && "Sign up with Apple"] }, void 0)] }), void 0));
};
