import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { createGlobalStyle } from "styled-components";
export const CssGlobalStyle = createGlobalStyle `
	/**
	 * Many css variables will be placed here.
	 * In the future these values can be updated.
	 */
	:root {
		--nav-height: 56px;
		--footer-height: 100px;

		// Snackbar styles
		--color-text-status-success: ${(p) => p.theme.statusSuccess};
		--color-text-status-error: ${(p) => p.theme.statusError};
		--color-text-status-warning: ${(p) => p.theme.statusWarning};
		--color-text-status-info: ${(p) => p.theme.statusInfo};

		--font-family-primary: "Noto Serif", serif;
		--font-family-secondary: "Inter", sans-serif;
	}

	html {
		width: 100%;
		height: 100%;
		display: table;
	}

	body {
		width: 100%;
		display: table-cell;
		// Required as part of ensure no page scroll (to keep height 100%)
		position: relative;
	}

	html, body {
		margin: 0;
		padding: 0;
		// To prevent page scroll
		// overflow-x: hidden;
		width: 100%;
		height: 100%;
		overflow: hidden;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}

	/** 
	 * NOTE: Any changes to the 'body' style should potentially 
	 * also be changed in index.ejs, so if the app crashes the 
	 * default body style is shown.
	 */
	body {
		${TypographyV3.Body};

		word-break: break-word;

		margin: 0;
	}

	button {
		background: none;
		border: none;
		margin: 0;
		padding: 0;
		cursor: pointer;
		color: ${(p) => p.theme.buttonPrimary};
	}

	#root {
		min-height: 100%;
		height: 100%;
	}

	:focus-visible {
		outline: ${(p) => p.theme.buttonPrimaryHover} auto 1px;
	}
`;
