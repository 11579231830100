import { jsx as _jsx } from "react/jsx-runtime";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import styled from "styled-components";
const Root = styled.div `
	& {
		${TypographyV3.FieldDescription};
	}
`;
export const FieldDescription = (p) => {
    if (!p.children) {
        return null;
    }
    return _jsx(Root, { children: p.children }, void 0);
};
