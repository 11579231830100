const initBoolean = (key, defaultValue) => {
    const v = initString(key, defaultValue ? "true" : "false");
    if (v == "") {
        return defaultValue;
    }
    return v.toLowerCase() == "true";
};
const initString = (key, defaultValue) => {
    const v = (window["_envs"][key] || "").trim();
    if (v == "") {
        return defaultValue;
    }
    return v;
};
export const Config = {
    GitLabCommit: initString("CI_COMMIT_SHORT_SHA", ""),
    DebugBuildId: initString("SUITE_WEB_CUSTOMER_BUILD_ID", ""),
    DebugInfoIsEnabled: initBoolean("SUITE_WEB_CUSTOMER_DEBUG_INFO_ENABLED", false),
    GoogleApiKey: initString("SUITE_WEB_CUSTOMER_GOOGLE_API_KEY", ""),
    GoogleClientId: initString("SUITE_WEB_CUSTOMER_AUTHENTICATION_GOOGLE_CLIENT_ID", ""),
    SegmentWriteKey: initString("SUITE_WEB_CUSTOMER_SEGMENT_WRITE_KEY", ""),
    StreamKey: initString("SUITE_WEB_CUSTOMER_STREAM_KEY", ""),
    UrlBase: initString("SUITE_WEB_CUSTOMER_URL_BASE", ""),
};
