var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ButtonRegister } from "@/src/common/components/button/ButtonRegister";
import { UserPasswordYup } from "@/src/common/utility/password/UserPassword";
import { FormId } from "@/src/enums/forms";
import { HyperlinkResolution } from "@/src/enums/hyperlink";
import { Form } from "@/src/features/common";
import { PasswordStrengthIndicator } from "@/src/features/common/PasswordStrengthIndicator";
import { t } from "@/src/features/Localization";
import { RegisterType } from "@/src/features/RegisterAccount/types";
import { RegisterOrActivateTermsOfServiceCheckbox } from "@/src/features/RegisterOrPasswordOrActivation/RegisterOrActivateTermsOfServiceCheckbox";
import { FormikTextField } from "@/src/features/utils/Formik/FormikTextField";
import { FormikTextFieldTypes } from "@/src/features/utils/Formik/formikTypes";
import { getSettingDetailsByType } from "@/src/modules/setting/type/SettingDetails";
import { SettingType } from "@/src/modules/setting/type/SettingType";
import { useHyperlinkResolveSingleMutation } from "@/src/redux/apiServices/suiteApi";
import { setCredentials } from "@/src/redux/slices/authSlice";
import { routes } from "@/src/routes";
import { SegmentEntryPoint, useSegmentAnalytics } from "@/src/utils/SegmentAnalytics";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import * as Yup from "yup";
const NewPasswordContainer = styled.div `
	max-width: 100%;
	// text-align: center;
`;
export const ActivateWithNewPassword = (p) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { segmentTrackEvent } = useSegmentAnalytics();
    const user = p.user;
    const hyperlink = p.hyperlink;
    const hyperlinkDetails = hyperlink.details;
    const [hyperlinkResolve, hyperlinkResolveState] = useHyperlinkResolveSingleMutation();
    const handleSubmit = ({ userUpdatedPassword }) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        segmentTrackEvent("select_confirm", {
            entrypoint: SegmentEntryPoint.CompleteActivation,
        });
        try {
            const response = (yield hyperlinkResolve({
                resolution: HyperlinkResolution.Submit,
                token: hyperlink.token,
                type: "basic",
                password: userUpdatedPassword,
            }).unwrap());
            dispatch(setCredentials({
                token: (_a = response.session) === null || _a === void 0 ? void 0 : _a.token,
                tokenStream: (_b = response.session) === null || _b === void 0 ? void 0 : _b.tokenStream,
            }));
            const settingActivate = getSettingDetailsByType(SettingType.GroupActivate, user.settings);
            const deeplink = settingActivate === null || settingActivate === void 0 ? void 0 : settingActivate.deeplinkWithHost;
            if (deeplink) {
                window.location.assign(deeplink);
            }
            else {
                history.push(routes.Home.toPath());
            }
        }
        catch (e) {
            // TODO: reidenzon - Do something!
            debugger;
        }
    });
    const ResetPasswordValidationSchema = Yup.object().shape({
        userUpdatedPassword: UserPasswordYup(),
        registerPrivacyPolicyCheckbox: Yup.boolean().oneOf([true], "Required"),
    });
    const formikInitialValues = {
        userUpdatedPassword: "",
        registerPrivacyPolicyCheckbox: false,
    };
    const formikConfig = {
        initialValues: formikInitialValues,
        enableReinitialize: true,
        validateOnBlur: true,
        validationSchema: ResetPasswordValidationSchema,
        onSubmit: handleSubmit,
    };
    /* ---------------------------- Render Component ---------------------------- */
    return (_jsx(NewPasswordContainer, { children: _jsx(Formik, Object.assign({}, formikConfig, { children: (formik) => (_jsxs(Form, Object.assign({ onSubmit: formik.handleSubmit, id: FormId.ResetPassword }, { children: [_jsx(FormikTextField, { fullWidth: true, formikId: "userUpdatedPassword", type: FormikTextFieldTypes.Password, label: t("changePassword_inputLabel_newPassword") }, void 0), _jsx(PasswordStrengthIndicator, { currentPassword: formik.values.userUpdatedPassword }, void 0), _jsx(RegisterOrActivateTermsOfServiceCheckbox, { registerType: RegisterType.Regular, formikId: "registerPrivacyPolicyCheckbox" }, void 0), _jsx(ButtonRegister, { isLoading: hyperlinkResolveState.isLoading, text: t("common_button_confirm") }, void 0)] }), void 0)) }), void 0) }, void 0));
};
