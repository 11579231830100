import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BadgeComingSoon } from "@/src/common/components/badge/BadgeComingSoon";
import { TypographyV3 } from "@/src/common/style/StyleTypographyV3";
import { Icons } from "@/src/features/common";
import styled from "styled-components";
const Root = styled.div `
	& {
		align-items: center;
		background: ${(p) => p.theme.cardBackground};
		border: 1px solid ${(p) => p.theme.divider};
		border-radius: 5px;
		display: flex;
		flex-direction: row;
		padding: 4px 12px;
		opacity: 0.5;
	}

	&.enabled {
		cursor: pointer;
		opacity: 1;
	}
`;
const RootLeft = styled.div `
	& {
		flex: 1;
	}
`;
const RootTitle = styled.div `
	& {
		${TypographyV3.Button};
		color: ${(p) => p.theme.buttonPrimary};
	}

	${Root}.enabled:hover & {
		color: ${(p) => p.theme.buttonPrimaryHover};
	}
`;
const RootRight = styled.div `
	& {
		align-items: center;
		display: flex;
	}
`;
const RootIcon = styled(Icons.ChevronRightAqua) `
	& path {
		fill: ${(p) => p.theme.buttonPrimary};
	}

	${Root}.enabled:hover & path {
		fill: ${(p) => p.theme.buttonPrimaryHover};
	}
`;
export const ServiceListItem = (p) => {
    const classList = [];
    p.isEnabled && classList.push("enabled");
    const handleClick = () => {
        var _a;
        if (p.isEnabled) {
            (_a = p.onClick) === null || _a === void 0 ? void 0 : _a.call(p);
        }
    };
    return (_jsxs(Root, Object.assign({ className: classList.join(" "), onClick: handleClick }, { children: [_jsx(RootLeft, { children: _jsx(RootTitle, { children: p.title }, void 0) }, void 0), _jsxs(RootRight, { children: [p.isEnabled && _jsx(RootIcon, {}, void 0), !p.isEnabled && _jsx(BadgeComingSoon, {}, void 0)] }, void 0)] }), void 0));
};
